:root {
  --primary-color: #000000;
  --brand-main-color: #ff99ff;
}

@font-face {
  font-family: "HafferXH-Regular";
  src: url("../../public/fonts/HafferXH-Regular.woff");
}

@font-face {
  font-family: "Ploni-Regular";
  src: url("../../public/fonts/ploni-regular-aaa.otf");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: HafferXH-Regular, Ploni-Regular, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent !important;
}

.text-shadow {
  text-shadow: 0 0 15px rgba(255, 255, 255, 0.5),
    0 0 10px rgba(100, 100, 100, 0.5), 1px 3px 3px rgba(0, 0, 0, 0.3);
}

.desktop-header-menu-link {
  color: var(--brand-main-color);
}

.desktop-header-menu-link:hover {
  color: #ffffff;
}

.mobile-header-menu-link {
  color: #ffffff;
}

.mobile-header-menu-link:hover {
  color: var(--brand-main-color);
}

.mobile-header-menu-link > img {
  fill: var(--brand-main-color);
  margin-right: 7px;
  margin-top: 0;
  margin-bottom: 0;
  transition: 0.3s;
}

.mobile-header-menu-link:hover > img {
  fill: var(--primary-color);
  transition: 0.3s;
}

p {
  font-family: Ploni-Regular;
  font-weight: 400;
}

h3 {
  font-family: Ploni-Regular;
  font-weight: 500;
  font-size: 1.15rem;
}

img {
  max-width: 100%;
}

footer {
  padding-bottom: 80px;
}

section {
  scroll-margin-top: 0px;
}

.h-100vh {
  height: 100vh;
}

.max-w-500px {
  max-width: 500px;
}

.cursor-pointer {
  cursor: pointer;
}

.pt-80px {
  padding-top: 80px;
}

.pb-80px {
  padding-bottom: 80px;
}

.pt-150px {
  padding-top: 150px;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-2 {
  padding-left: 0.75rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-20px {
  font-size: 20px;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.bg-black {
  background: #000000 !important;
}

.bg-black-transparent-50 {
  background: rgba(0, 0, 0, 0.5) !important;
}

.bg-black-transparent-80 {
  background: rgba(0, 0, 0, 0.8) !important;
}

.bg-none {
  background-color: transparent !important;
}

.border-red {
  border-color: #ff0000 !important;
}

.btn {
  border-radius: 0;
}

.btn-primary {
  color: #ffffff !important;
  background-color: #000000 !important;
  border: 1px #000000 solid;
}

.btn-primary:hover {
  color: #000000 !important;
  background-color: #ffffff !important;
  border: 1px #000000 solid;
}

.btn-outline-primary {
  color: #000000 !important;
  background-color: #ffffff !important;
  border: 1px #000000 solid;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: #000000 !important;
  border: 1px #000000 solid;
}

.btn-outline-primary:active:focus {
  box-shadow: none;
}

input {
  border-radius: 0 !important;
}

input:focus-visible {
  border-radius: 0 !important;
  outline: none;
}

input::placeholder {
  color: #b5b5b5;
  opacity: 1;
}

.float-right {
  float: right;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.border-bottom-white {
  border-bottom: 1px solid #fff;
}

header {
  background-color: unset;
}

#content {
  background: transparent !important;
}

#logo img {
  max-height: 40px;
}

#back-to-top svg {
  fill: var(--brand-main-color);
  width: 16px;
  height: 15px;
  position: absolute;
  right: 7px;
  top: 7px;
}

#back-to-top > svg {
  transition: opacity 0.2s ease-out;
}

#back-to-top:hover > svg {
  transform: scale(1.1);
  -o-transition: 0.9s;
  -ms-transition: 0.9s;
  -moz-transition: 0.9s;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.hero-h1 {
  font-size: 35px !important;
}

.white-stroke {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}

.hero-h2 {
  font-size: 30px !important;
}

.brand-text {
  color: var(--brand-main-color) !important;
}

.brand-background {
  background-color: var(--brand-main-color);
}

.brand-transparent-gradient-background {
  background: linear-gradient(
    180deg,
    var(--brand-main-color) 12.24%,
    rgba(255, 255, 255, 0) 95.39%
  );
}

.how-it-works-container {
  background: transparent !important;
  position: absolute;
  padding: 15px;
  border-top: 2px solid var(--brand-main-color);
}

.how-it-works-card {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  background-color: #ffffff !important;
  padding: 15px;
}

.background-video {
  z-index: -1;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.logo-loop-container {
  height: 70px;
  background-color: black;
  position: relative;
  padding-top: 18px;
  padding-bottom: 18px;
}

.logo-loop-stripe {
  position: relative;
  height: 34px;
  background-image: url("../../public/images/logo-padded.png");
  background-repeat: repeat-x;
  background-size: auto 100% !important;
  animation: marquee 30s infinite linear;
}

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -100vw;
  }
}

.owl-stage {
  max-height: 600px;
  overflow: hidden;
  margin-bottom: 60px;
}

.owl-nav {
  color: var(--primary-color);
}

.hero-img-container {
  z-index: 0;
}

.hero-img-container:hover {
  z-index: 10;
}

.nft_pic {
  border: none !important;
  box-shadow: none !important;
}

.nft__item {
  border: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  background: unset !important;
  margin: 10px;
}

.nft__item_buy_btn {
  border: 1px solid var(--primary-color) !important;
  border-radius: 0 !important;
}

.nft__item_buy_btn:hover {
  background: #ffffff !important;
  color: var(--primary-color) !important;
}

.nft__item_click span:after {
  display: none;
}

.nft__item_preview {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.aspect-ratio-1-1 {
  aspect-ratio: 1/1;
}

.darken {
  filter: brightness(50%);
}

.blurred::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  pointer-events: none;
  top: 0;
  left: 0;
}

.p-05 {
  padding: 0.125rem !important;
}

.timer-unit {
  font-size: 30px;
  font-weight: 500;
}

.timer-descr {
  font-size: 20px;
  font-weight: 500;
}

.font-50px {
  font-size: 50px;
}

.font-40px {
  font-size: 40px;
}

.font-30px {
  font-size: 30px;
}

.font-20px {
  font-size: 20px;
}

.release-marquee-container {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  overflow: hidden;
}

.question-container {
  border-bottom: 2px solid var(--primary-color);
  padding-top: 40px;
  padding-bottom: 40px;
}

footer a {
  color: white;
  fill: white;
}

footer a:hover {
  color: var(--brand-main-color) !important;
  fill: var(--brand-main-color) !important;
}

.contact-submit-btn {
  box-shadow: unset !important;
}

.contact-submit-btn:hover {
  color: var(--brand-main-color) !important;
  border-bottom: 1px solid var(--brand-main-color) !important;
}

.contact-submit-btn:active {
  filter: brightness(85%);
}

.arrow-icon {
  height: 15px;
  margin-left: 0.5rem;
  margin-bottom: 6px;
}

.session-follow-header {
  color: var(--brand-main-color);
  text-transform: uppercase;
}

.session-footer-link {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 103.5%;
  text-transform: uppercase;
}

.subtitle {
  color: var(--primary-color);
  font-weight: 500 !important;
}

.swiper {
  z-index: 0 !important;
}
.swiper-container {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  width: auto;
}

.swiper-slide img {
  object-fit: cover;
  object-position: center top;
  height: 33vh;
  width: 100%;
}

.email-input {
  background: transparent;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  color: white;
  border-radius: unset;
}

.nowrap {
  white-space: nowrap;
}

@media only screen and (min-width: 400px) {
  .timer-unit {
    font-size: 40px;
  }
}

@media only screen and (min-width: 576px) {
  .hero-h1 {
    font-size: 50px !important;
  }
}

@media only screen and (min-width: 768px) {
  .pt-md-80px {
    padding-top: 80px !important;
  }

  .pt-md-150px {
    padding-top: 150px !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-left {
    text-align: left !important;
  }

  .hero-h1 {
    font-size: 70px !important;
  }

  .hero-h2 {
    font-size: 40px !important;
  }

  .how-it-works-description {
    height: 150px;
  }

  .swiper-slide img {
    height: 35vh;
  }

  .max-w-md-600px {
    max-width: 600px !important;
  }
}

@media only screen and (min-width: 992px) {
  section {
    scroll-margin-top: 90px;
  }

  .pt-lg-150px {
    padding-top: 150px !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .max-w-lg-800px {
    max-width: 800px !important;
  }

  .max-w-lg-100 {
    max-width: 100% !important;
  }

  .hero-h1 {
    font-size: 80px !important;
  }

  .hero-h2 {
    font-size: 60px !important;
  }

  .swiper-slide img {
    height: 50vh;
  }

  .timer-unit {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1200px) {
  .w-xl-50 {
    width: 50% !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-left {
    text-align: left !important;
  }

  .max-w-xl-100 {
    max-width: 100% !important;
  }

  footer {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .max-w-xxl-100 {
    max-width: 100% !important;
  }

  .timer-unit {
    font-size: 40px;
  }

  footer {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
}
